<template>
  <v-card rounded="0"  flat>
    <v-card flat width="256">
      <v-navigation-drawer  color="#2c9412" app  permanent>
          <v-list-item link to='/' class="px-2">
            <v-list-item-avatar >
              <v-img   src="@/assets/emblem.png"></v-img>
            </v-list-item-avatar>

            <v-list-item-title  style="color:white">Home</v-list-item-title>

            <!-- <v-btn
              icon
              @click.stop="mini = !mini"
            >
              <v-icon>mdi-home</v-icon>
            </v-btn> -->
          </v-list-item>

          <v-divider></v-divider>        
          <v-list dense rounded>
            <v-list-item  v-for="link in links"  :key="link.name" link :to="link.routinglink" >
              <v-list-item-icon class="iconclass">
                <v-icon style="color:white">{{ link.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title style="color:white; font-size:14px">{{ link.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
      </v-navigation-drawer>
    </v-card>
  </v-card>
</template>
<script>
export default {
  props:['drawer','links'],
  data() {
    return {
      items: [
        { title: "Home", icon: "mdi-view-dashboard" },
        { title: "About", icon: "mdi-forum" },
      ],
    };
  },
};
</script>
