<template>
<v-main>
  <MainToolBar :links="links"/>
  <sequential-entrance fromBottom>
    <v-container class="hero" fluid>
      <v-row style="padding-top:70px" >
        <v-spacer></v-spacer>
        <v-col md="3" xs="12" sm="6">
          <v-hover  v-slot="{ hover }"  open-delay="200" close-delay="200">
            <v-card
            class="mx-auto mt-16"
            :elevation="hover ? 16 : 2"
            link
            to="/components/listcomponents"
            max-width="90%"
            max-height="400px"
            outlined
        >
            <v-list-item three-line>
            <v-list-item-content>
                <div class="text-overline mb-4">
                <v-icon>mdi-layers-triple</v-icon>
                </div>
                <v-list-item-title class="text-h5 mb-1">
                Components
                </v-list-item-title>
                <v-list-item-subtitle>Register, Processing and Respond to all appeals</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
                tile
                size="120"
                color="white"
            ><v-img max-width="100%" src="@/assets/project-management.png"></v-img></v-list-item-avatar>
            </v-list-item>


        </v-card>
          </v-hover>
        </v-col>
        <v-col md="3" xs="12" sm="6">
          <v-hover  v-slot="{ hover }"  open-delay="200" close-delay="200">
            <v-card
            class="mx-auto mt-16"
            :elevation="hover ? 16 : 2"
            link
            to="/data_entry/projects_data"
            max-width="90%"
            max-height="400px"
            outlined
        >
            <v-list-item three-line>
            <v-list-item-content>
                <div class="text-overline mb-4">
                <v-icon>mdi-database-plus</v-icon>
                </div>
                <v-list-item-title class="text-h5 mb-1">
                Data Entry
                </v-list-item-title>
                <v-list-item-subtitle>Customize and configure different system parts.</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
                tile
                size="120"
                color="white"
            ><v-img max-width="100%" src="@/assets/cloud-data.png"></v-img></v-list-item-avatar>
            </v-list-item>


        </v-card>
          </v-hover>
        </v-col>
        <v-col md="3" xs="12" sm="6">
          <v-hover  v-slot="{ hover }"  open-delay="200" close-delay="200">
            <v-card
            class="mx-auto mt-16"
            :elevation="hover ? 16 : 2"
            link
            to="/reports/general_report"
            max-width="90%"
            max-height="400px"
            outlined
        >
            <v-list-item three-line>
            <v-list-item-content>
                <div class="text-overline mb-4">
                <v-icon>mdi-poll</v-icon>
                </div>
                <v-list-item-title class="text-h5 mb-1">
                Reports
                </v-list-item-title>
                <v-list-item-subtitle>Extract  analysed summary Reports from different system processes</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
                tile
                size="120"
                color="white"
            ><v-img max-width="100%" src="@/assets/business-report.png"></v-img></v-list-item-avatar>
            </v-list-item>


        </v-card>
          </v-hover>
        </v-col>
        <v-col md="3" xs="12" sm="6">
          <v-hover  v-slot="{ hover }"  open-delay="200" close-delay="200">
            <v-card
            class="mx-auto mt-16"
            :elevation="hover ? 16 : 2"
            link
            to="/dtpme/objectives"
            max-width="90%"
            max-height="400px"
            outlined
        >
            <v-list-item three-line>
            <v-list-item-content>
                <div class="text-overline mb-4">
                <v-icon>mdi-magnify</v-icon>
                </div>
                <v-list-item-title class="text-h5 mb-1">
                M&E
                </v-list-item-title>
                <v-list-item-subtitle>Digital Tanzani Project Monitoring and Evalation</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
                tile
                size="120"
                color="white"
            ><v-img max-width="100%" src="@/assets/testing.png"></v-img></v-list-item-avatar>
            </v-list-item>


        </v-card>
          </v-hover>
        </v-col>
        <v-col md="3" xs="12" sm="6">
          <v-hover  v-slot="{ hover }"  open-delay="200" close-delay="200">
            <v-card
            class="mx-auto mt-16"
            :elevation="hover ? 16 : 2"
            link
            to="/settings/institutions"
            max-width="90%"
            max-height="400px"
            outlined
        >
            <v-list-item three-line>
            <v-list-item-content>
                <div class="text-overline mb-4">
                <v-icon>mdi-cog-sync-outline</v-icon>
                </div>
                <v-list-item-title class="text-h5 mb-1">
                Settings
                </v-list-item-title>
                <v-list-item-subtitle>Customize and configure different system parts.</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
                tile
                size="120"
                color="white"
            ><v-img max-width="100%" src="@/assets/settings.png"></v-img></v-list-item-avatar>
            </v-list-item>


        </v-card>
          </v-hover>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
  </sequential-entrance>
</v-main>
</template>

<script>  
import MainToolBar from '../modules/digitaltp_shared/MainToolBar .vue'
export default {
  name: "DashBoard",
  components:{MainToolBar},
  data: () => ({
      hovered:true,
    overlay: false,
  }),
};
</script>

<style >
.hero {
  background: url('../assets/mtnew.png');
  background-size: cover;
  height: 100vh;
}
</style>


