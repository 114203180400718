var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('MainToolBar',{attrs:{"links":_vm.links}}),_c('sequential-entrance',{attrs:{"fromBottom":""}},[_c('v-container',{staticClass:"hero",attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"padding-top":"70px"}},[_c('v-spacer'),_c('v-col',{attrs:{"md":"3","xs":"12","sm":"6"}},[_c('v-hover',{attrs:{"open-delay":"200","close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto mt-16",attrs:{"elevation":hover ? 16 : 2,"link":"","to":"/components/listcomponents","max-width":"90%","max-height":"400px","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_c('v-icon',[_vm._v("mdi-layers-triple")])],1),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" Components ")]),_c('v-list-item-subtitle',[_vm._v("Register, Processing and Respond to all appeals")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"120","color":"white"}},[_c('v-img',{attrs:{"max-width":"100%","src":require("@/assets/project-management.png")}})],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"3","xs":"12","sm":"6"}},[_c('v-hover',{attrs:{"open-delay":"200","close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto mt-16",attrs:{"elevation":hover ? 16 : 2,"link":"","to":"/data_entry/projects_data","max-width":"90%","max-height":"400px","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_c('v-icon',[_vm._v("mdi-database-plus")])],1),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" Data Entry ")]),_c('v-list-item-subtitle',[_vm._v("Customize and configure different system parts.")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"120","color":"white"}},[_c('v-img',{attrs:{"max-width":"100%","src":require("@/assets/cloud-data.png")}})],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"3","xs":"12","sm":"6"}},[_c('v-hover',{attrs:{"open-delay":"200","close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto mt-16",attrs:{"elevation":hover ? 16 : 2,"link":"","to":"/reports/general_report","max-width":"90%","max-height":"400px","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_c('v-icon',[_vm._v("mdi-poll")])],1),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" Reports ")]),_c('v-list-item-subtitle',[_vm._v("Extract analysed summary Reports from different system processes")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"120","color":"white"}},[_c('v-img',{attrs:{"max-width":"100%","src":require("@/assets/business-report.png")}})],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"3","xs":"12","sm":"6"}},[_c('v-hover',{attrs:{"open-delay":"200","close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto mt-16",attrs:{"elevation":hover ? 16 : 2,"link":"","to":"/dtpme/objectives","max-width":"90%","max-height":"400px","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" M&E ")]),_c('v-list-item-subtitle',[_vm._v("Digital Tanzani Project Monitoring and Evalation")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"120","color":"white"}},[_c('v-img',{attrs:{"max-width":"100%","src":require("@/assets/testing.png")}})],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"3","xs":"12","sm":"6"}},[_c('v-hover',{attrs:{"open-delay":"200","close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto mt-16",attrs:{"elevation":hover ? 16 : 2,"link":"","to":"/settings/institutions","max-width":"90%","max-height":"400px","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_c('v-icon',[_vm._v("mdi-cog-sync-outline")])],1),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" Settings ")]),_c('v-list-item-subtitle',[_vm._v("Customize and configure different system parts.")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"120","color":"white"}},[_c('v-img',{attrs:{"max-width":"100%","src":require("@/assets/settings.png")}})],1)],1)],1)]}}])})],1),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }