import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import projectRouters from '../modules/digitaltp_projects/projectRouters.js'
import settingsRouters from '../modules/digitaltp_settings/settingsRouters.js'


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  ...projectRouters,
  ...settingsRouters
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
